import {
    Container,
    TapasContainer,
    TextContainer,
    PhoneNumber,
    Tapa,
    Charola,
    Extra,
    ExtraItem,
    ExtraPrice,

    Item,
    Img,
    InfoContainer,
    Title,
    List,
} from './style';
import Showcase from '../../components/showcase';
import LazyLoad from 'react-lazy-load';
import data from './data.json';
import React from 'react';

const CharolasBotaneras = () => {

    const tapasImg = [
        "images/tapas/alcachofa.png",
        "images/tapas/boqueron.png",
        "images/tapas/calamar.png",
        "images/tapas/camaron.png",
        "images/tapas/champ_relleno.png",
        "images/tapas/chistorra.png",
        "images/tapas/croqueta.png",
        "images/tapas/empanadilla.png",
        "images/tapas/ensaladilla.png",
        "images/tapas/esparrago.png",
        "images/tapas/fuet.png",
        "images/tapas/gulas.png",
        "images/tapas/huevo_relleno.png",
        "images/tapas/jamon.png",
        "images/tapas/jamon_queso.png",
        "images/tapas/mejillon.png",
        "images/tapas/montadito.png",
        "images/tapas/morcilla.png",
        "images/tapas/padron.png",
        "images/tapas/papa_brava.png",
        "images/tapas/piquillo.png",
        "images/tapas/sobrasada.png",
        "images/tapas/tigre.png",
        "images/tapas/tortilla.png",
    ]

    const showcaseFondo = 'images/showcases/charolas.jpg';

    return (
        <>
            <Showcase
                fondo={showcaseFondo}
                m1={'Haga su selección y llamenos'}
            />

            <Container>

                <TapasContainer>
                    {data.items.map((item, index) => {
                        return (
                            <Item key={index}>
                                <LazyLoad key={index}>
                                    <Img src={item.img}/>
                                </LazyLoad>
                                <InfoContainer>
                                    <Title>{item.title}</Title>
                                    <List>{item.price}</List>
                                </InfoContainer>
                            </Item>
                        )
                    })}
                </TapasContainer>

                <TapasContainer>
                    {tapasImg.map((item, index)=> {
                        return (
                            <LazyLoad key={index}>
                                <Tapa src={item}/>
                            </LazyLoad>
                        )
                    })}
                </TapasContainer>

                <Charola src={'images/charolas/botaneras1.jpg'}/>

                <TextContainer>
                    <Extra>
                        <ExtraItem>12 unidades</ExtraItem>
                        <ExtraPrice>$300</ExtraPrice>
                    </Extra>
                    <Extra>
                        <ExtraItem>24 unidades</ExtraItem>
                        <ExtraPrice>$600</ExtraPrice>
                    </Extra>
                    <Extra>
                        <ExtraItem>48 unidades</ExtraItem>
                        <ExtraPrice>$1200</ExtraPrice>
                    </Extra>
                    <PhoneNumber>83-78-67-02</PhoneNumber>
                    <PhoneNumber>83-35-26-60</PhoneNumber>
                </TextContainer>

            </Container>
        </>
    );
};

export default CharolasBotaneras;
