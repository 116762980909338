import {
    YellowSub,
    TotalContainer,
    Row
} from './style';
import { connect } from 'react-redux';
import React from 'react';

const Total = ({ order }) => {
    const dayOfWeek = new Date().getDay();
    const totalReducer = (acc, curr) => acc + curr;

    const getArrayOfPrices = function() {
        let list = [];
        for (let i = 0; i < order.length; i++) {
            for (let x = 1; x <= order[i].count; x++) {
                list.push(order[i].price);
            }
        }
        return list;
    }

    // desc is applied to some dishes only so different array is needed
    const getArrayOfDescPrices = function() {
        let list = [];
        for (let i = 0; i < order.length; i++) {

            if (!order[i].desc)
                continue;

            for (let x = 1; x <= order[i].count; x++) {
                list.push(order[i].price);
            }
        }
        return list;
    }

    const totalDiscount  = function() {
        // NOTE(Fermin): Discount mondays, tuesdays and wednesdays
        if (dayOfWeek >= 4 || dayOfWeek === 0)
        // NOTE(Fermin): Discount mondays and wednesdays
        //if (dayOfWeek !== 1 && dayOfWeek !== 3)
        // NOTE(Fermin): Discount tuesdays and wednesdays
        //if (dayOfWeek !== 2 && dayOfWeek !== 3)
            return 0;

        let arrayOfDescPrices = getArrayOfDescPrices();
        if (arrayOfDescPrices.length < 3)
            return 0;

        let desc = 0;
        const platesToDiscount = Math.floor(arrayOfDescPrices.length/3)
        arrayOfDescPrices.sort(function(a, b){return a-b});
        for (let i = 0; i < platesToDiscount; i++) {
            desc += arrayOfDescPrices[i];
        }
        return desc;
    }

    const totalArray = getArrayOfPrices();
    const renderTotal = () => {
        if (totalDiscount() > 0) {
            return (
                <TotalContainer>
                    <Row>
                        <YellowSub>Orden:</YellowSub>
                        <YellowSub>
                            ${totalArray.reduce(totalReducer)}
                        </YellowSub>
                    </Row>
                    <Row>
                        <YellowSub>Descuento:</YellowSub>
                        <YellowSub>
                            ${totalDiscount()}
                        </YellowSub>
                    </Row>
                    <Row>
                        <YellowSub>Total:</YellowSub>
                        <YellowSub>
                            ${totalArray.reduce(totalReducer)-totalDiscount()}
                        </YellowSub>
                    </Row>
                </TotalContainer>
            )
        }
        else if (order.length > 0) {
            return (
                <TotalContainer>
                    <Row>
                        <YellowSub>Total:</YellowSub>
                        <YellowSub>
                            ${totalArray.reduce(totalReducer)-totalDiscount()}
                        </YellowSub>
                    </Row>
                </TotalContainer>
            )
        }
        else {
            return (
                <TotalContainer>
                    <Row>
                        <YellowSub>Total:</YellowSub>
                        <YellowSub>
                            $0
                        </YellowSub>
                    </Row>
                </TotalContainer>
            )
        }
    }

    return (
        <>
            {renderTotal()}
        </>
    );
};

const mapStateToProps = state => {
    return ({ order: state.order });
};

export default connect(mapStateToProps, {})(Total);
