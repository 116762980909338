import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
`

export const Item = styled.div`
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px 10px;
`

export const Img = styled.img`
    height: 140px;
    object-fit: contain; 
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    padding: 5px 20px;
    justify-content: center;
`

export const Title = styled.h1`
    font-family: 'Caveat',cursive;
    color: #ff0000;
    font-size: 40px;
    font-weight: bold;
    text-align: Left;
    line-height: 44px;
`

export const List = styled.div`
    font-size: 18px;
`

export const PhoneNumber = styled.p`
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    color: #722F37;
`

