import {
    Container,
    Item,
    Img,
    InfoContainer,
    Title,
    List,
    PhoneNumber
} from './style';
import Showcase from '../../components/showcase';
import LazyLoad from 'react-lazy-load';
import data from './data.json';
import React from 'react';

const Eventos = () => {
    return (
        <>
            <Showcase
                fondo={ 'images/showcases/eventos.jpg'}
                m1={'Haga su selección y llamenos'}
            />
            <Container>
                {data.items.map((item, index) => {
                    return (
                        <Item key={index}>
                            <LazyLoad>
                                <Img src={item.img}/>
                            </LazyLoad>
                            <InfoContainer>
                                <Title>{item.title}</Title>
                                <List>{item.list}</List>
                            </InfoContainer>
                        </Item>
                    )
                })}
                <PhoneNumber>83-78-67-02</PhoneNumber>
                <PhoneNumber>83-35-26-60</PhoneNumber>
            </Container>
        </>
    );
};

export default Eventos;

